
import {
  Component,
  Prop,
  Vue,
} from 'nuxt-property-decorator'

@Component
export default class Share extends Vue {
  @Prop() title!: string
  @Prop() shareUrl!: string

  get canShare(): boolean {
    if (!process.client) {
      return false
    }
    return Boolean(navigator.share)
  }

  async share() {
    try {
      const shareData = {
        title: this.title,
        url: this.shareUrl,
      }
      await navigator.share(shareData)
    } catch (err) {
      console.error(err)
    }
  }
}
