
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ArticleBlocksDynamicZone, LinktreeBlocksDynamicZone, PageBlocksDynamicZone } from '../graphql/generated'

@Component({})
export default class DynamicBlocksRenderer extends Vue {
  @Prop() readonly blocks!: ArticleBlocksDynamicZone[] | PageBlocksDynamicZone[] | LinktreeBlocksDynamicZone[]

  getComponentName(typeName: string): string {
    return typeName.replace('Component', '')
  }
}
